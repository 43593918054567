import React from "react"
import css from "@styled-system/css"

import { Box, H3, Text, Sidebar, RichText, Image } from "components"

const TeamSidebar = ({ sidebarContent, setSidebarContent }) => (
  <Sidebar
    showDialog={sidebarContent ? true : false}
    setShowDialog={setSidebarContent}
    maxWidth={[327, 512]}
    side="right"
  >
    <Box pt={[48, 64]} pb={5} px={[3, 4]}>
      {sidebarContent && sidebarContent.image && (
        <Box mx={[-3, -4]}>
          <Image src={sidebarContent.image} />
        </Box>
      )}
      <H3
        children={
          sidebarContent && sidebarContent.name && sidebarContent.name.text
        }
        mt={[3, 4]}
      />
      <Text
        children={
          sidebarContent && sidebarContent.position && sidebarContent.position
        }
        mt={1}
        mb={3}
        fontSize={0}
      />
      <Box width={[16, 32]} height="1px" my={3} bg="border" />
      <RichText
        content={sidebarContent && sidebarContent.description}
        css={css({ hyphens: "auto" })}
      />
    </Box>
  </Sidebar>
)

export default TeamSidebar
