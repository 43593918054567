import React from "react"
import css from "@styled-system/css"

import { Image, Box, Flex, H1, H6, Text, Wrapper, Carousel } from "components"

const Testimonial = ({ author, image, quote, index }) => (
  <Box pr={[4, 5]}>
    <H6 children={index < 9 ? "0" + (index + 1) : index + 1} mb={4} />
    <Flex
      flexWrap="wrap"
      css={css({
        m: [-2, -3],
        "> *": {
          width: ["100%", "50%"],
          p: [2, 3],
        },
      })}
    >
      <Box>
        <Image src={image} alt={image.alt} />
      </Box>
      <Box position="relative" zIndex={0}>
        <svg
          width={52}
          height={47}
          viewBox="0 0 52 47"
          fill="none"
          css={css({
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
            color: "text",
          })}
        >
          <path
            d="M13.047 27.18c2.143.63 3.782 1.825 4.917 3.587 1.26 1.636 1.89 3.65 1.89 6.04 0 3.02-.945 5.474-2.836 7.362C15.128 46.056 12.795 47 10.022 47c-3.026 0-5.484-.944-7.375-2.831C.882 42.155 0 39.702 0 36.807c0-1.51.126-2.957.378-4.341.378-1.51 1.072-3.65 2.08-6.418L10.4 0h9.076l-6.429 27.18zm32.146 0c2.143.63 3.782 1.825 4.916 3.587 1.26 1.636 1.891 3.65 1.891 6.04 0 3.02-.946 5.474-2.836 7.362C47.273 46.056 44.94 47 42.167 47c-3.025 0-5.483-.944-7.374-2.831-1.765-2.014-2.648-4.467-2.648-7.362 0-1.51.127-2.957.379-4.341.378-1.51 1.071-3.65 2.08-6.418L42.545 0h9.077l-6.43 27.18z"
            fill="currentColor"
            opacity={0.1}
          />
        </svg>
        {quote && <Text children={quote} />}
        {author && (
          <Text
            children={"– " + author}
            mt={1}
            fontSize={0}
            fontWeight="bold"
            textAlign="right"
          />
        )}
      </Box>
    </Flex>
  </Box>
)

const Testimonials = ({ title, testimonials }) => (
  <>
    {title && (
      <Wrapper as="section" id="testimonial" pt={[5, 6]}>
        <H1 children={title.text} mb={[4, 5]} />
      </Wrapper>
    )}
    {testimonials && testimonials[0] && testimonials[0].quote && (
      <Carousel
        as="section"
        id="testimonial-carousel"
        settings={{
          infinite: true,
          initialSlide: 0,
          slidesToShow: 2,
          slidesToScroll: 2,
          variableWidth: false,
          responsive: [
            {
              breakpoint: 1280,
              settings: {
                initialSlide: -0.5,
                slidesToShow: 1.5,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                initialSlide: 0,
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 640,
              settings: {
                initialSlide: 0,
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        }}
        responsive={false}
        children={[
          ...testimonials.map((testimonial, index) => {
            if (testimonial.image && testimonial.image.url) {
              return (
                <Testimonial
                  {...testimonial}
                  index={index}
                  key={`testimonial` + index}
                />
              )
            } else {
              return null
            }
          }),
        ]}
      />
    )}
  </>
)

export default Testimonials
