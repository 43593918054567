import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { CallToAction, PageHeader, SEO, Split } from "components"
import { usePreviewData } from "utils"

import Values from "./_values"
import Team from "./_team"
import TeamSidebar from "./_teamSidebar"
import Testimonials from "./_testimonials"

function AboutPage() {
  const [sidebarContent, setSidebarContent] = useState(undefined)
  const data = useStaticQuery(
    graphql`
      {
        prismicAbout {
          data {
            seo_title {
              text
            }
            seo_description {
              text
            }
            seo_image {
              url
              alt
            }
            hero_image {
              url
              alt

              fluid(maxWidth: 1600) {
                ...GatsbyPrismicImageFluid
              }
            }
            hero_video {
              url
            }
            hero_title {
              text
            }
            hero_subtitle {
              text
            }
            teamwork_image {
              url
              alt
              fluid(maxWidth: 1200) {
                ...GatsbyPrismicImageFluid
              }
            }
            teamwork_title {
              text
            }
            teamwork_description {
              html
            }
            teamwork_color
            teamwork_side
            why_image {
              url
              alt
              fluid(maxWidth: 1200) {
                ...GatsbyPrismicImageFluid
              }
            }
            why_video {
              url
            }
            why_title {
              text
            }
            why_description {
              html
            }
            why_button_text
            why_button_link {
              link_type
              url
            }
            why_style
            why_pattern
            why_color
            why_side
            testimonials_title {
              text
            }
            testimonials {
              image {
                url
                alt

                fluid(maxWidth: 512) {
                  ...GatsbyPrismicImageFluid
                }
              }
              quote
              author
            }
            values_title {
              text
            }
            values {
              value
              description {
                html
              }
            }
            values_color
            team {
              image {
                url
                alt

                fluid(maxWidth: 512) {
                  ...GatsbyPrismicImageFluid
                }
              }
              name {
                text
              }
              position
              description {
                html
              }
            }
          }
        }
      }
    `
  )
  // Merge preview data with static query if possible
  const MERGED_DATA = usePreviewData(data)
  const { prismicAbout } = MERGED_DATA

  return (
    <>
      <SEO
        title={prismicAbout.data.seo_title}
        description={prismicAbout.data.seo_description}
        image={prismicAbout.data.seo_image}
      />
      <PageHeader
        image={prismicAbout.data.hero_image}
        video={prismicAbout.data.hero_video}
        title={prismicAbout.data.hero_title}
        subtitle={prismicAbout.data.hero_subtitle}
      />
      <CallToAction
        image={prismicAbout.data.why_image}
        video={prismicAbout.data.why_video}
        heading={prismicAbout.data.why_title}
        text={prismicAbout.data.why_description}
        button={prismicAbout.data.why_button_text}
        link={prismicAbout.data.why_button_link}
        style={prismicAbout.data.why_style}
        pattern={prismicAbout.data.why_pattern}
        bg={prismicAbout.data.why_color}
        align={prismicAbout.data.why_side}
      />
      <Testimonials
        title={prismicAbout.data.testimonials_title}
        testimonials={prismicAbout.data.testimonials}
      />
      {prismicAbout.data.values && prismicAbout.data.values[0] && (
        <Values
          values={prismicAbout.data.values}
          title={prismicAbout.data.values_title}
          color={prismicAbout.data.values_color}
        />
      )}
      {prismicAbout?.data?.teamwork_image &&
        prismicAbout?.data?.teamwork_description && (
          <Split
            image={prismicAbout.data.teamwork_image}
            heading={prismicAbout.data.teamwork_title}
            text={prismicAbout.data.teamwork_description}
            bg={prismicAbout.data.teamwork_color}
            align={prismicAbout.data.teamwork_side}
          />
        )}
      {prismicAbout.data.team && prismicAbout.data.team[0] && (
        <Team
          team={prismicAbout.data.team}
          setSidebarContent={setSidebarContent}
        />
      )}
      <TeamSidebar
        sidebarContent={sidebarContent}
        setSidebarContent={setSidebarContent}
      />
    </>
  )
}

export default AboutPage
