import React from "react"
import css from "@styled-system/css"

import { Box, ColorMode, Flex, H1, H3, RichText, Wrapper } from "components"

const Value = ({ label, description }) => (
  <Box
    pt={4}
    borderTop="1px solid"
    borderColor="border"
    css={css({
      "&:not(:last-of-type)": {
        mb: [4, 5],
      },
    })}
  >
    <Flex
      flexWrap="wrap"
      css={css({
        m: [-2, -4],
        "> *": {
          p: [2, 4],
        },
      })}
    >
      <Box width={["100%", 1 / 2]}>
        <H3>{label}</H3>
      </Box>
      <Box width={["100%", 1 / 2]}>
        <RichText content={description} />
      </Box>
    </Flex>
  </Box>
)

const Values = ({ title, values, color }) => (
  <ColorMode mode="dark">
    <Wrapper
      as="section"
      id="values"
      bg={color || "brand.teal.0"}
      pt={[5, 6]}
      flush
    >
      <Wrapper py={5}>
        {title && title.text && <H1 children={title.text} mb={4} />}
        <Box>
          {values.map((value, index) => (
            <Value
              label={value.value}
              description={value.description}
              key={"value" + index}
            />
          ))}
        </Box>
      </Wrapper>
    </Wrapper>
  </ColorMode>
)

export default Values
