import React from "react"
import css from "@styled-system/css"
import { useTheme } from "@emotion/react"
import { rgba } from "polished"

import { Image, Box, Flex, H1, H5, Text, Wrapper } from "components"

const Member = ({ image, label, position, onClick }) => {
  const theme = useTheme()

  return (
    <Box width={["50%", null, "33.333%"]}>
      <Box
        onClick={onClick}
        css={css({
          cursor: "pointer",
          img: { transition: "all 300ms ease-in-out !important" },
          ":hover": { img: { transform: "scale(1.05)" } },
        })}
      >
        <Image src={image} />
      </Box>
      <H5 mt={[2, 3]} fontSize={[2, 3, 4]}>
        <span
          onClick={onClick}
          css={css({
            textDecoration: "none",
            color: "inherit",
            backgroundImage: `linear-gradient(to bottom, ${rgba(
              theme.colors.text,
              0.25
            )} 50%, ${rgba(theme.colors.text, 0.25)} 50%)`,
            backgroundRepeat: "repeat-x",
            backgroundSize: "1px 1px",
            backgroundPosition: "0 100%",
            cursor: "pointer",
          })}
        >
          {label}
        </span>
      </H5>
      <Text children={position} mt="6px" fontSize={0} />
    </Box>
  )
}

const Team = ({ team, setSidebarContent }) => (
  <Wrapper as="section" id="team" pt={[5, 6]}>
    <H1>Leadership team</H1>
    <Box mt={[4, 5]}>
      <Flex
        flexWrap="wrap"
        css={css({
          m: [-2, -4],
          "> *": {
            p: [2, 4],
          },
        })}
      >
        {team.map((member, index) => (
          <Member
            onClick={() => {
              setSidebarContent(member)
            }}
            image={member.image}
            label={member.name && member.name.text}
            position={member.position}
            key={"teamMember" + index}
          />
        ))}
      </Flex>
    </Box>
  </Wrapper>
)

export default Team
